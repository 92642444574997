import { createSelectors } from '@mri-platform/shared/entity';
import { createSelector } from '@ngrx/store';
import { ApiConnection } from '../../models';

export const apiConnectionsSelectors = createSelectors(ApiConnection);

export const selectByPlatformDatasource = (queryParams: Partial<ApiConnection>) => {
  return createSelector(apiConnectionsSelectors.selectEntities, entities => {
    const { platformId, databaseId } = queryParams;
    return entities.filter(
      entity =>
        (!platformId || entity.platformId === platformId) &&
        (!databaseId || !entity.databaseId || entity.databaseId === databaseId)
    );
  });
};
