/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccessToken } from './access-token';
import { ApiConnection } from './api-connection';
import { Portfolio } from './portfolio';
import { Scenario } from './scenario';

export interface JobFileConnectionsData {
  connection: JobsConnectionData;
  context: string;
  exportTo: string;
  useMap: number | string;
  filters: any;
  endpoint: string;
}

export interface JobApiConnectionsData {
  sourceConnection: JobsConnectionData;
  destinationConnection: JobsConnectionData;
  destinationPlatform: number;
  destinationParameters: any;
  portfolio: Portfolio | null;
  scenario: Scenario | null;
}

export interface JobApiFiltersData {
  journalPeriodType: JournalPeriodType;
  sourceConnection2Id: string;
  allEntities: boolean;
  allAccounts: boolean;
  allSuites: boolean;
  selectedProjects: string;
  selectedEntities: string;
  selectedAccounts: string;
  selectedSuites: string;
  periodFrom: Date;
  periodTo: Date;
  leasesExpireFrom: Date;
  excludeLeases: boolean;
  filterByProjects: boolean;
  filterByEntities: boolean;
}

export interface JobsConnectionData {
  parent?: string;
  connection: ApiConnection;
}

export interface JobConfiguration {
  exportType: string;
  coaConfigFile: File;
  recurringChargesConfigFile: File;
  context: AccessToken;
  connection?: JobsConnectionData;
}

export interface DropdownOptions {
  text: string;
  value: string | number | boolean;
}

export enum ExportType {
  DefaultExport = 'DefaultExport',
  ArgusCloudExport = 'ArgusCloudExport',
  None = 'None'
}

export enum JournalPeriodType {
  Open = 'Open',
  Closed = 'Closed',
  Both = 'Both'
}
