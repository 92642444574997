import { ExportType } from '@mri-platform/import-export/common-state';
import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContext, CrudAction } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';
import { JobStatus } from './jobs-enum';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum JobType {
  Export = 'Export',
  Import = 'Import',
  ValidationImport = 'ValidationImport'
}

export enum JobLogInterval {
  PastYear = 'Past year',
  PastMonth = 'Past month',
  PastWeek = 'Past week'
}

export enum JobActions {
  ViewErrors = 'View Errors',
  Execute = 'Execute',
  Download = 'Download',
  Regenerate = 'Regenerate'
}

export interface JobLog {
  id: number;
  platformId: number;
  name: string;
  runTime: string;
  type: JobType;
  exportType?: ExportType;
  identifier: string;
  context: string;
  connection: string;
  destination: string;
  status: JobStatus;
  uri: string;
  logType?: string;
  exportFileId?: number;
  message?: string;
  scheduleFrequency: string;
  runImmediately: boolean;
  accessTokenId: number;
  records?: number;
  createdByFirstName: string;
  createdByLastName: string;
  mapperType?: string;
  mapperName?: string;
  licenseId?: string;
  dataSourceName?: string;
  username?: string;
  totalRecords?: number;
  canDownload?: boolean;
  sheetName?: string;
}

export interface JobLogQuery extends JobLog {
  fromDateTime: string;
}

export enum JobLogCustomAction {
  DownloadJobLogs = 'DownloadJobLogs'
}

export type JobLogAction = JobLogCustomAction | CrudAction;
export const JobLogAction = { ...JobLogCustomAction, ...CrudAction };

export const JobLog = createModelMetadata<JobLog>({
  entityName: 'JobLog',
  authorization: {
    [CrudAction.Read]: AuthzContext.readFor(EntityName.JobLog),
    [JobLogAction.DownloadJobLogs]: {
      action: [JobLogAction.DownloadJobLogs, JobLogAction.Read],
      resource: EntityName.JobLog
    }
  },
  sortComparer: (a, b) => new Date(b.runTime).getTime() - new Date(a.runTime).getTime()
});

export const isSuccess = (jobLog: JobLog) =>
  jobLog.type.toLowerCase() === JobType.Export.toLowerCase() &&
  jobLog.status.toLowerCase() === JobStatus.Succeeded.toLowerCase();
