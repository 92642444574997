import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DefaultEntityService, EntityIdType, QueryDef, toQueryParams } from '@mri-platform/shared/entity';
import { ChangeSet, ChangeSetItem, DefaultDataServiceConfig, changeSetItemFactory as cif } from '@ngrx/data';
import { Observable } from 'rxjs';
import { finalize, switchMapTo } from 'rxjs/operators';
import { AccessToken, ApiConnection, ApiConnectionParams, ApiConnectionsInfo, ConnectionMode } from '../models';
import { accesstokenConnectionSelectors } from './selectors';
import { selectByPlatformDatasource } from './selectors/api-connection-selectors';

@Injectable({ providedIn: 'root' })
export class ApiEntityService extends DefaultEntityService<ApiConnection> {
  constructor(
    private apiConfig: DefaultDataServiceConfig,
    injector: Injector,
    private http: HttpClient
  ) {
    super(ApiConnection.entityName, injector);
  }

  getEnabledConnections$(params: Partial<ApiConnectionParams>): Observable<ApiConnection[]> {
    this.clearCache();
    return this.getWithQuery(toQueryParams(params));
  }

  getConnectionsInfo(params: Pick<ApiConnection, 'platformId' | 'databaseId' | 'mode'>) {
    this.setLoading(true);
    const api = `${this.apiConfig.root}/apiconnections/info`;
    return this.http.get<ApiConnectionsInfo>(api, { params }).pipe(finalize(() => this.setLoading(false)));
  }

  apisByAccessToken$(queryParams: Partial<AccessToken>, mode: ConnectionMode): Observable<ApiConnection[]> {
    return this.store.select(accesstokenConnectionSelectors.selectByAccessToken(queryParams, mode));
  }

  entitiesByPlatformId$(id: EntityIdType) {
    return this.store.select(accesstokenConnectionSelectors.selectEntitiesForPlatformId(id));
  }

  saveConnectionEntities(connections: ApiConnection[]) {
    const connectionUpdates = connections.map(({ id, isEnabled, licenseType }) => ({ id, isEnabled, licenseType }));
    const changes: ChangeSetItem[] = [cif.upsert(ApiConnection.entityName, connectionUpdates)];

    const api = `${this.apiConfig.root}/apiconnections`;

    const changeSet: ChangeSet = { changes, tag: 'Update connections data' };
    return this.entityDispatcher.saveEntities(changeSet, api);
  }

  getAllConnections$(params: Partial<ApiConnection>): Observable<ApiConnection[]> {
    params = { ...params, includeDisabled: true };
    const query = QueryDef.getWithQuery(params);
    return this.setWithQuery(query).pipe(switchMapTo(this.store.select(selectByPlatformDatasource(params))));
  }
}
