export interface AgSharedConfigData {
  trainingAcademyUrl: string;
  showDetailedErrorMessages: boolean;
  region: string;
}

const defaults: AgSharedConfigData = {
  trainingAcademyUrl: '',
  showDetailedErrorMessages: false,
  region: ''
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
export interface AgSharedConfig extends AgSharedConfigData {}

export class AgSharedConfig {
  constructor(json?: AgSharedConfigData) {
    json = json ?? defaults;
    Object.assign(this, defaults, json);
  }
}
